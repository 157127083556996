import React, {useState} from 'react';
import AgreementHistoryItem from "../AgreementHistoryItem/AgreementHistoryItem";
import './agreement-history.css'

const AgreementHistory = ({data}) => {
        const [show, setShow] = useState(false)

        const handleChange = (e) => {
            setShow(!show)
        }

        return (
            <>
                {/*<div className={'document-details-title'}>Лист согласования:</div>*/}
                <div className={"agreement-history-checkbox-container"}>
                    <input className={"agreement-history-checkbox"} type="checkbox" id="scales" name="scales" checked={show} onChange={handleChange}/>
                    <label className={"agreement-history-label"} htmlFor="scales">Показать лист согласования</label>
                </div>
                {show &&
                    <div className={'document-details-container'}>
                        <div>
                            {data.map((item, idx) => (<AgreementHistoryItem key={idx} item={item}/>))}
                        </div>
                    </div>
                }
            </>
        );
    }
;

export default AgreementHistory;